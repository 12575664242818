import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <header className="major">
          <h2>Musicoteràpia preventiva durant l'embaràs</h2>
        </header>
        <p>
          La <strong>musicoteràpia preventiva durant l'embaràs</strong> és una eina poderosa per a les mares embarassades que volen afavorir el seu benestar emocional i físic durant aquesta etapa tan especial. A través de la música, s’afavoreixen la <strong>relaxació</strong>, l’<strong>equilibri emocional</strong> i el <strong>vincle prenatal</strong> entre la mare i el nadó.
        </p>
        <h2>Beneficis de la musicoteràpia durant l'embaràs</h2>
        <p>
          <ul>
            <li><strong>Reducció de l'estrès i l'ansietat:</strong> Mitjançant tècniques de relaxació i escolta activa, la música ajuda a disminuir la tensió emocional i millorar el benestar general.</li>
            <li><strong>Vincle prenatal:</strong> La música facilita la comunicació afectiva entre la mare i el nadó, afavorint la connexió emocional i la calma.</li>
            <li><strong>Estimulació sensorial per al nadó:</strong> Els nadons, fins i tot dins el ventre, poden percebre sons i melodies, afavorint el seu desenvolupament cognitiu i emocional.</li>
            <li><strong>Suport durant el part:</strong> La musicoteràpia prepara les mares per afrontar el procés del part amb més confiança, utilitzant la música per a la relaxació i la concentració.</li>
            <li><strong>Afavoriment del benestar físic de la mare:</strong> A través de la música, es poden treballar tècniques de respiració i moviment per alleujar malestars típics de l’embaràs com el dolor lumbar o la tensió muscular.</li>
            <li><strong>Suport emocional en grup:</strong> Les sessions grupals permeten a les mares compartir experiències, crear un vincle amb altres dones que viuen una etapa similar i sentir-se acompanyades, cosa que afavoreix el sentiment de comunitat i suport mutu.</li>
          </ul>
        </p>
        <p>
          Les sessions o tallers de musicoteràpia durant l'embaràs són grupals, adaptades a les necessitats de cada dona, i s'ofereixen en un espai segur i respectuós. Si estàs buscant una forma de cuidar-te a tu mateixa i al teu nadó mentre et prepares per a la maternitat, la musicoteràpia és una opció ideal per viure l'embaràs d'una manera més conscient i equilibrada.
        </p>
      </section>
      <section id="three">
        <h2>Propers tallers</h2>
        <p>
          Per consultar els tallers d'estimulació musical que es realitzaran properament,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            clica aquí!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
